body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-menu-item-selected {
  background: linear-gradient(
    270deg,
    #1DA1F2 0%,
    #17183B 100%
  ) !important;
}

.ant-pro-card {
  border: 1px solid var(--Grey-button, #e0e0e0);
  border-radius: 6px;
}

.ant-table-thead .ant-table-cell {
  background-color: white !important;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  border-bottom: 1px solid #a6a6a6;
}

.user-profile-card-nav .ant-tabs-nav {
  margin: 0 0 0 1rem;
}

.user-profile-card-nav .ant-card-actions li:first-of-type {
  margin: 10;
}

.user-profile-card-nav {
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.candidate-photo {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ant-descriptions-item-label {
  font-weight: 600;
}

.ant-descriptions-item-content {
  font-size: 16px;
}

.ant-card {
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.ant-card-body {
  padding: 24px;
}

.ant-tag {
  margin-bottom: 5px;
}

.ant-typography {
  margin-bottom: 0;
}

a {
  color: #1DA1F2;
}